<template>
  <v-container>
    <v-toolbar flat class="ma-0 pa-0"
      v-show="loggedIn === 1">
      <v-spacer></v-spacer>
      <!-- v-col v-show="showFind" cols="4" sm="4" md="4">
        <v-text-field
          class="mt-2 pt-2"
          v-show="showFind"
          v-model=searchValue
          ref="text_find"
          label="Buscando por.."
          prepend-inner-icon="mdi-magnify"
          append-outer-icon="mdi-close"
          @click:append-outer="cancelFind()"
          v-on:keyup="keymonitor">
        </v-text-field>
      </v-col>
      <v-btn
        v-show="showBtnPrint"
        @click.stop="btnPrint()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-printer
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >imprimir
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnNew"
        @click.stop="btnNew()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-file-plus
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >nuevo
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnEdit"
        @click.stop="btnEdit()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-pencil
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >editar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnCancel"
        @click.stop="btnCancel()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-close
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >Cancelar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnSave"
        @click.stop="btnSave()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-check
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >salvar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnFind"
        @click.stop="btnFind()"
        class="ma-1 pa-1"
        :plain="!this.showFind ? true:false"
        :color="this.showFind ? 'primary':''"
        >
        <v-icon large color="blue darken-4"
          >mdi-magnify
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >
          {{ showFind ? 'Buscando' : 'Buscar' }}
        </span>
      </v-btn -->
      <v-btn
        v-show="showBtnPass"
        @click.stop="dialogPass = true"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-key-variant
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >contraseña
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnClose"
        @click.stop="$router.push({name: 'Logged', params: {}})"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-chevron-left
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >cerrar
        </span>
      </v-btn>
    </v-toolbar>


    <v-card>
      <v-card-title class="py-2 my-2">
        <span :class="classform">{{title}}</span>
        <v-divider
          class="mx-4"
          inset
          vertical
          >
        </v-divider>
        <v-spacer></v-spacer>
        <!-- v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn fab x-small v-on="on" :color="buttoncolor" :to="routeLogged">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Salir</span>
        </v-tooltip -->
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                <v-text-field id="text_firstname" ref="text_firstname" :disabled="!isEditing" dense class="py-0 my-0"
                  v-model="formUserData.firstname" label="Nombre(s)"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                <v-text-field id="text_midname" ref="text_midname" :disabled="!isEditing" dense class="py-0 my-0"
                  v-model="formUserData.midname" label="Paterno"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                <v-text-field id="text_lastname" ref="text_lastname" :disabled="!isEditing" dense class="py-0 my-0"
                  v-model="formUserData.lastname" label="Materno"></v-text-field>
              </v-col>

              <!-- v-col cols="12" sm="6" md="4" class="py-1 my-1">
                <v-text-field id="text_name" ref="text_name" :disabled="!isEditing" dense class="py-0 my-0"
                  v-model="formUserData.name" label="Nombre"></v-text-field>
              </v-col -->
              <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                <v-text-field id="text_email" ref="text_email" :disabled="!isEditing" dense class="py-0 my-0"
                  v-model="formUserData.email" label="Usuario"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                <v-text-field id="text_personalemail" ref="text_personalemail" :disabled="!isEditing" dense class="py-0 my-0"
                  v-model="formUserData.personalemail" label="Email"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                <v-text-field id="text_movil" ref="text_movil" :disabled="!isEditing" dense class="py-0 my-0"
                  v-model="formUserData.movil" label="Movil"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                <v-text-field id="text_comment" ref="text_comment" :disabled="!isEditing" dense class="py-0 my-0"
                  v-model="formUserData.comment" label="Comentarrio"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                <v-img
                  :src="require('@/assets/avatar.png')"
                  class="my-3"
                  contain
                  height="100"
                ></v-img>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>

  <v-row justify="center">
    <v-dialog v-model="dialogPass" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Cambiar contraseña</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-text-field id="text_oldPass" ref="text_oldPass" v-on:keyup="keymonitor" :rules="[rules.required]"
                  v-model="oldPass" label="Contraseña actual" :disabled="!isEditingPass"
                  clearable
                  prepend-icon="mdi-lock"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  :hint="`${hintoldPass}`"
                  persistent-hint
                  @click:append="showPass = !showPass"
                  ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field id="text_newPass" ref="text_newPass" v-on:keyup="keymonitor" :rules="[rules.required]"
                  v-model="newPass" label="Nueva contraseña" :disabled="!isEditingPass"
                  clearable
                  prepend-icon="mdi-lock"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  :hint="`${hintnewPass}`"
                  persistent-hint
                  @click:append="showPass = !showPass"
                  ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field id="text_conPass" ref="text_conPass" v-on:keyup="keymonitor" :rules="[rules.required]"
                  v-model="conPass" label="Confirmar la nueva contraseña" :disabled="!isEditingPass"
                  clearable
                  prepend-icon="mdi-lock"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  :hint="`${hintconPass}`"
                  persistent-hint
                  @click:append="showPass = !showPass"
                  ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogPass = false" v-show="!showBtnCancel">Cerrar</v-btn>
          <v-btn color="blue darken-1" text @click="dialogPass = false" v-show="showBtnCancel">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="savePass();" v-show="showBtnSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  </v-container>
</template>
<script>
  export default {
    name: 'UserData',
    props: {
      //
    },
    components: {
      //
    },
    data: () => ({
      //
      formUserData: {
        //
      },
      //
      // searchValue: null,
      // showFind: false,
      showBtnPass: true,
      showBtnClose: true,
      showBtnCancel: true,
      showBtnSave: true,
      isEditing: false,
      isEditingPass: true,
      //
      rules: {
        required: value => !!value || 'Campo Requerido.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      dialogPass: false,
      oldPass: '',
      newPass: '',
      conPass: '',
      showPass: false,
      hintoldPass: '',
      hintnewPass: '',
      hintconPass: '',
      //
    }),
    methods: {
      initialize: async function () {
        //
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.formUserData = await this.copyObjects(this.userData);
        let str = await this.formUserData.movil;
        let ele = await str.replace( /(\d{2})(\d{2})(\d{6})/,'$1-$2-$3' );
        this.formUserData.movil = ele;
        //
      },
      savePass: async function () {
        if(await this.validationFields()){
          //
          const token = await JSON.parse(sessionStorage.getItem('localToken'));
          if (await token) {
            let apiRoute = await 'setpass';
            // let formData = await this.arrDialog;
            let formData = await {
              id: this.userData.id,
              oldPass: this.oldPass,
              newPass: this.newPass,
              // conPass: this.conPass
            }
            formData['employee_id'] = await this.userData.id;
            const returnData = await true;
            let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
            // console.log(dataResponse);
            if (dataResponse) {
              let message = await dataResponse.message;
              let type = await 'info';
              await this.$store.dispatch('loadMessage', { msg: message, type: type });
              if (dataResponse.error === 0){
                this.showBtnCancel = false;
                this.showBtnSave = false;
                this.isEditingPass = false;
                this.showPass = true;
              }
            }
          }
          //
        }
        /*
        this.$store.dispatch('loadUpPage')
        let message = ''
        let type = 'info'
        if(await this.validationFields()){
          this.$store.dispatch('loadOverlay', true)
          await this.setPass()
          if(await this.loadData===1 && await this.responseData.length !== 0){
            message = this.responseData.message
            if(this.responseData.error===0){
            // this.closEditDialog()
            this.dialogPass = false
            } else {
              type = 'error'
            }
          } else {
            type = 'error'
            message = 'Error: No actualizo el registro'
          }
          await this.$store.dispatch('loadMessage', { msg: message, type: type })
          if(await this.responseData.column){
            await this.getFocus(this.responseData.column)
          }
        }
        */
      },
      validationFields: function () {
        let isValid = true;

        if(isValid===true){
          isValid = this.isEmpty(
            this.oldPass,
            'El Campo Contraseña actuual no puede ser blanco',
            'text_oldPass'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.newPass,
            'El Campo Nueva Contraseña no puede ser blanco',
            'text_newPass'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.conPass,
            'El Campo Confirmar Nueva Contraseña no puede ser blanco',
            'text_conPass'
          );
        }

        if(isValid === true && (this.newPass!==this.conPass)){
          let message = 'Error. La nueva contraseña no coincide con la confirmación'
          this.$store.dispatch('loadMessage', { msg: message, type: 'error' });
          if(this.newPass === ''){
            this.getFocus('text_newPass');
          }
          if(this.conPass === ''){
            this.getFocus('text_conPass');
          }
          isValid = false;
        }
        return isValid;
      },
      keymonitor: function (event) {
        if (event.key === 'Enter') {
          this.savePass()
        }
      },
      processData: function () {
        //
      }
    },
    created () {
      //
      this.initialize();
    },
    mounted () {
      //
    },
    updated () {
      //
    },
    watch: {
      //
      dialogPass (val) {
        if (val) {
          this.oldPass = '';
          this.newPass = '';
          this.conPass = '';
          this.getFocus('text_oldPass');
        }
      }
    },
    computed: {
      //
      classform() {
        if (this.$vuetify.breakpoint.name === 'xs'){
            return 'body-2 pa-0 ma-0 text-left';
        } else {
            return 'body-1 pa-0 ma-0 text-left';
        }
      }
      //
    }
  }
</script>
